import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Image from "next/image";
const DButton = dynamic(() => import('../elements/DButton', { ssr: false }));
const AutocompleteWithPreview = dynamic(() => import('../Algolia/SearchFile', { ssr: false }));

export default function Subheader({ background, title, description, device = 'desktop' }) {


  return (
    <>
      <div className={(device == 'mobile') ? 'flex items-center relative w-full mx-auto mt-65 h-[520px] bg-gradient-to-r from-[#4396c0] to-[#1a2f73]' : 'flex items-center relative mt-65 w-full mx-auto h-[520px]  bg-gradient-to-r from-[#4396c0] to-[#1a2f73]'}>
        {(device == 'desktop') ?
          <Image
            src={background}
            // fill={true}
            className={(device == 'mobile') ? 'w-full h-[520px] d-none' : 'w-full h-[520px] min-h-full'}
            priority={true}
            quality={(device == 'mobile') ? 40 : 80}
            // sizes="(min-width: 808px) 50vw, 100vw"
            // layout="fill"
            // layout="responsive"
            layout="fill"
            objectFit="cover"

            alt={"Background Image"}
          /> : <></>
        }

        {/* The child element */}
        <div className={`w-11/12 xl:max-w-screen max-w-screen-2xl absolute xl:left-0 lg:left-16 left-4 lg:right-0 right-4 flex flex-col mx-auto lg:justify-between`}>
          <h1 className={`text-3xl font-semibold text-white`}>{title}</h1>
          <p className='mx-0 text-white mb-6'>{description}</p>
          <div style={{ maxWidth: '500px' }}>
            <AutocompleteWithPreview />
          </div>
          <div className='flex gap-2'>
            <DButton title="Report Store" redirect="/report-store.php" />
            <DButton title="Business Consulting" redirect="/consulting" />
          </div>
        </div>
      </div>
    </>
  )
}